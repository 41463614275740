//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';
import Roles from '@/security/roles';
import { i18n } from '@/vueI18n';
// import WalletViewToolbar from '@/modules/wallet/components/wallet-view-toolbar.vue';
import moment from 'moment';
// import { i18n } from '@/i18n';
// function i18n(key, args) {
//       // return i18n(code);
//       return this.$t(key, args);
//     }

const { fields } = UserModel;

export default {
  name: 'app-wallet-view-page',

  props: ['id'],
  mounted() {
    // this.doFilter();
    this.doMountTable(this.$refs.table);
  },
  data() {
    return {
      date: '',
      dense: false,
      model: null,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        sortBy: 'createdAt',
        descending: false,
        // rowsNumber: xx if getting data from a server
      },
      columns: [
        {
          name: 'type',
          field: 'type',
          label: i18n(
            'entities.wallet.transactions.fields.type',
          ),
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          sortable: false,
        },
        {
          name: 'description',
          field: 'description',
          label: i18n(
            'entities.wallet.transactions.fields.description',
          ),
          align: 'center',
        },
        {
          name: 'amount',
          field: 'amount',
          label: i18n(
            'entities.wallet.transactions.fields.amount',
          ),
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          sortable: false,
        },
        // {
        //   name: 'roles',
        //   field: 'roles',
        //   label: i18n('entities.wallet.fields.role'),
        //   align: 'center',
        // },
        // {
        //   name: 'status',
        //   field: 'disabledAsStatus',
        //   label: i18n('entities.wallet.fields.status'),
        //   align: 'center',
        // },
        {
          name: 'date',
          field: 'date',
          label: i18n(
            'entities.wallet.transactions.fields.date',
          ),
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          sortable: false,
        },
      ],
      rows: [
        {
          type: 'Deposit',
          description: 'Description',
          amount: '1,000 SAR',
          date: '5/12/2021',
        },
        {
          type: 'Deposit',
          description: 'Description',
          amount: '1,000 SAR',
          date: '5/12/2021',
        },
        {
          type: 'Withdraw',
          description: 'Description',
          amount: '1,000 SAR',
          date: '5/12/2021',
        },
      ],
    };
  },

  // components: {
  //   [WalletViewToolbar.name]: WalletViewToolbar,
  // },

  computed: {
    // mapGetters to return loading and record
    totalDeposit() {
      var total = 0;
      this.rows.forEach((element) => {
        if (element.type == 'deposit') {
          total += element.amount;
        }
      });
      return total;
    },
    totalWithdraw() {
      var total = 0;
      this.rows.forEach((element) => {
        if (element.type == 'withdraw') {
          total += element.amount;
        }
      });
      return total;
    },
    ...mapGetters({
      record: 'wallet/view/record',
      loading: 'wallet/view/loading',
      // rows:'transaction/list/rows',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),

    // this computed  for enableRtl

    isRTL() {
      return localStorage.getItem('language') == 'ar';
    },

    fields() {
      return fields;
    },
  },

  // call this function then call another one is doFind() to create new user

  async created() {
    // await this.doFind(this.id);
  },

  // mapAction call the  functions from store { doNew , doAdd }

  methods: {
    ...mapActions({
      doFind: 'wallet/view/doFind',
      doFetch: 'transaction/list/doFetchTransactions',
      doMountTable: 'wallet/list/doMountTable',
    }),
    checkValue(val, reason, details) {
      console.log(val, reason, details);
      this.date = this.date.substring(0, 7);
      if (reason === 'month') {
        console.log(this.date);
        this.$refs.monthPicker.hide();
      }
    },
    presenterDateTime(val) {
      return val
        ? moment(val)
            .locale(this.currentLanguageCode)
            .format('dddd D MMM, YYYY') +
            this.i18n('common.at') +
            moment(val)
              .locale(this.currentLanguageCode)
              .format('hh:mm A')
        : 'ـــ';
    },
    async doFilter() {
      const filter = {
        walletId: this.id,
      };
      return this.doFetch({
        filter,
      });
    },

    // display data in form fields

    presenter(record, fieldName) {
      return UserModel.presenter(record, fieldName);
    },

    // display role of user

    roleLabelOf(roleId) {
      return Roles.labelOf(roleId);
    },

    // i18n for localization

    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
  },
};
